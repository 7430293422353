<template>
    <DxValidationGroup ref="validateMaBaoMat">
        <div class="row">
            <div class="xs12 mb-4">
                <DxTextBox
                    v-model="MaBaoMat"
                    label="Mã bảo mật (*)"
                    labelMode="floating"
                    styling-mode="underlined"
                    validationMessageMode="always"
                    :mode="passwordModeMK"
                    :buttons="btnMatMa"
                    :showClearButton="true"
                >
                    <DxValidator ref="">
                        <DxRequiredRule message="Mã bảo mật không được bỏ trống!" />
                        <DxStringLengthRule
                            :max="128"
                            message="Mã bảo mật không được lớn hơn 128 ký tự!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </div>
    </DxValidationGroup>
</template>
<script>
import { DxButton, DxTextBox, DxValidationGroup, DxValidator } from "devextreme-vue";
import { DxRequiredRule, DxStringLengthRule } from "devextreme-vue/data-grid";
import { onIonViewWillEnter } from "@ionic/vue";
export default {
    components: {
        DxButton,
        DxTextBox,
        DxValidationGroup,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        onIonViewWillEnter,
    },
    data() {
        return {
            passwordModeMK: "password",
            MaBaoMat: null,
            btnMatMa: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-eye",
                        type: "normal",
                        onClick: () => {
                            this.passwordModeMK =
                                this.passwordModeMK === "text" ? "password" : "text";
                        },
                    },
                },
            ],
            KhoaXacNhan: false,
            TextXacNhan: "XÁC NHẬN",
        };
    },
    methods: {
        resetMaBaoMat() {
            this.MaBaoMat = "";
        },
    },
    created() {},
};
</script>
